import React, { useEffect, useState } from "react";
import "./Docs.css";
import DocumentCard from "../../Components/DocumentCard/DocumentCard";
import { USER_ID } from "./../../Constants/User";
import avatar2 from "../../images/avatar.png";
import { Documents } from "../../Apis/List/Models/Documents";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../redux/features/alertSlice";
import { setAcitveNav } from "../../redux/features/userSlice";
import { dataInfo } from "../../pruebasJson/data";
import InfoModal2 from "../../Components/InfoModal/InfoModal2";
import notification from "../../images/notification-logo.png";
import { SendAgain } from "../../Apis/Documents/Models/SendAgain";
import { ToastContainer, toast } from "react-toastify";
import { GetDocument } from "../../Apis/Documents/Models/GetDocuments";
import { SendCopy } from "../../Apis/Documents/Models/SendCopy";

const Docs = () => {
  const [stepInfo, setStepInfo] = useState(0);
  const [listDocumentSend, setListDocumentSend] = useState([]);
  const [listDocumentReceived, setListDocumentReceived] = useState();
  const [listDocumentRead, setListDocumentRead] = useState();
  const [accountId, setAccountId] = useState("");
  const [documentId, setDocumentId] = useState("");
  const currentUserId = USER_ID();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const year = new Date().getFullYear();
  const { favoriteLenguaje } = useSelector((state) => state.user);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);
  const handleClose3 = () => setShow3(false);

  const listNDA = async () => {
    dispatch(showLoading());

    const classRef = new Documents();
    await classRef.List({ MembersId: parseInt(currentUserId) });
    let result = classRef.ResponseBody;

    result.ListDocumentsSent.sort((a, b) => {
      const dateA = new Date(a.CreatedOn);
      const dateB = new Date(b.CreatedOn);
      return dateB - dateA;
    });

    result.ListDocumentsReceived.sort((a, b) => {
      const dateA = new Date(a.CreatedOn);
      const dateB = new Date(b.CreatedOn);
      return dateB - dateA;
    });

    dispatch(hideLoading());
    setListDocumentSend(result.ListDocumentsSent);
    setListDocumentReceived(result.ListDocumentsReceived);
  };

  const sendAgain = async () => {
    dispatch(showLoading());
    try {
      const classRef = new SendAgain();
      await classRef.List({
        AccountId: parseInt(accountId),
        DocumentId: parseInt(documentId),
      });
      let result = classRef.ResponseBody;
      if (result.Status === "Successful!") {
        dispatch(hideLoading());
        toast.success(dataInfo[favoriteLenguaje][5]?.successNDA);
        listNDA();
      }
    } catch (error) {
      dispatch(hideLoading());
      console.log("Error", error);
    }
  };

  const sendParams = (account, document) => {
      setAccountId(account);
      setDocumentId(document);
  };

  const getDocument = async () => {
    try {
      dispatch(showLoading());
      const classRef = new GetDocument();
      await classRef.List({
        AccountId: parseInt(accountId),
        DocumentId: parseInt(documentId),
        IsFromReceiver: true,
      });
      let result = classRef.ResponseBody;
      toast.success(dataInfo[favoriteLenguaje][5]?.successNDA);
      console.log("Recipient id", result.DocumentInfo.AccountId_Recipient);
      console.log("Recipient id2", accountId);
      dispatch(hideLoading());
    } catch (error) {
      dispatch(hideLoading());
      console.log("Error", error);
    }
  };

  const sendCopyDocument = async () => {
    try {
      dispatch(showLoading());
      const classRef = new SendCopy();
      await classRef.List({
        AccountId: parseInt(accountId),
        DocumentId: parseInt(documentId),
      });
      let result = classRef.ResponseBody;
      if (result.Status === "Successful!") {
        dispatch(hideLoading());
        toast.success(dataInfo[favoriteLenguaje][5]?.successNDA);
        listNDA();
      }
    } catch (error) {
      dispatch(hideLoading());
      console.log("Error", error);
    }
  };

  useEffect(() => {
    dispatch(setAcitveNav("docs"))
    listNDA();
  }, []);
  return (
    <>
    <div className="docs-principal-container">
      <div className="main-doc-container">
        <div className="row w-100 d-flex justify-content-center">
          <div className="center-info-right d-flex justify-content-center">
            <div className="nda-info-container2">
              <div className="nda-info-title">NDA</div>
              {/* <button onClick={()=>{
                console.log("prueba", accountId, documentId)
              }}>Prueba</button> */}
              <div className="w-100 d-flex justify-content-center mt-2">
                <div className="info-greenline"></div>
              </div>
              <div className="info-tabs-conteiner">
                <div
                  onClick={() => {
                    setStepInfo(0);
                  }}
                  style={{
                    color: `${
                      stepInfo === 0 ? "#383E45" : "rgba(56, 62, 69, 0.5)"
                    }`,
                    paddingBottom: `${stepInfo === 0 ? "5px" : "0px"}`,
                    borderBottom: `${
                      stepInfo === 0
                        ? "2px solid #06B576"
                        : "1px solid transparent"
                    }`,
                  }}
                >
                  {dataInfo[favoriteLenguaje][3]?.all}
                </div>
                <div
                  onClick={() => {
                    setStepInfo(1);
                  }}
                  style={{
                    color: `${
                      stepInfo === 1 ? "#383E45" : "rgba(56, 62, 69, 0.5)"
                    }`,
                    paddingBottom: `${stepInfo === 1 ? "5px" : "0px"}`,
                    borderBottom: `${
                      stepInfo === 1
                        ? "2px solid #06B576"
                        : "1px solid transparent"
                    }`,
                  }}
                >
                  {dataInfo[favoriteLenguaje][3]?.incoming}
                </div>
                <div
                  onClick={() => {
                    setStepInfo(2);
                  }}
                  style={{
                    color: `${
                      stepInfo === 2 ? "#383E45" : "rgba(56, 62, 69, 0.5)"
                    }`,
                    paddingBottom: `${stepInfo === 2 ? "5px" : "0px"}`,
                    borderBottom: `${
                      stepInfo === 2
                        ? "2px solid #06B576"
                        : "1px solid transparent"
                    }`,
                  }}
                >
                  {dataInfo[favoriteLenguaje][3]?.outgoing}
                </div>
                <div
                  onClick={() => {
                    setStepInfo(3);
                  }}
                  style={{
                    color: `${
                      stepInfo === 3 ? "#383E45" : "rgba(56, 62, 69, 0.5)"
                    }`,
                    paddingBottom: `${stepInfo === 3 ? "5px" : "0px"}`,
                    borderBottom: `${
                      stepInfo === 3
                        ? "2px solid #06B576"
                        : "1px solid transparent"
                    }`,
                  }}
                >
                  {dataInfo[favoriteLenguaje][3]?.executed}
                </div>
              </div>

              {stepInfo === 0 ? (
                <div className="documents-container">
                  <div
                    className="mb-4"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <label
                      htmlFor="exampleFormControlInput10"
                      className="form-label label-title"
                    >
                      {dataInfo[favoriteLenguaje][2]?.search}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      //   value={formulario.Email}
                      name="Search"
                      //   onChange={handleChange2}
                      style={{ padding: "10px", height: "52px" }}
                      id="exampleFormControlInput10"
                      placeholder={`🔎 ${dataInfo[favoriteLenguaje][2]?.search}`}
                      required
                      autoComplete="false"
                    />
                  </div>
                  <div className="container01">
                    {listDocumentSend?.map((item, index) => {
                      const dateTimeParts = item.CreatedOn.split(" ");
                      const date = dateTimeParts[0].replace(/-/g, "/");
                      const timeParts = dateTimeParts[1].split(":");
                      const time = `${timeParts[0]}:${timeParts[1]} ${dateTimeParts[2]}`;
                      return (
                        <DocumentCard
                          key={index}
                          img="out"
                          name={item.InvitationName}
                          date={date}
                          hour={time}
                          status={
                            item?.DocOutStatus.Label === "Executed" &&
                            item?.DocInStatus.Label === "Executed"
                              ? `${dataInfo[favoriteLenguaje][3]?.executed}`
                              : `${dataInfo[favoriteLenguaje][3]?.sent}`
                          }
                          imgContact={item.AvatarUrl ? item.AvatarUrl : avatar2}
                          color={
                            item?.DocOutStatus.Label === "Executed" &&
                            item?.DocInStatus.Label === "Executed"
                              ? "#06b576"
                              : "#EA4335"
                          }
                          enterprise={item.EntityName}
                          onClick={() => {
                            // navigate(
                            //   `/view-document/${item.AccountId_Recipient}/${item.Id}/false`
                            // );
                            setShow(true);
                            sendParams(item.AccountId_Recipient, item.Id);
                          }}
                        />
                      );
                    })}

                    {listDocumentReceived?.map((item, index) => {
                      const dateTimeParts = item.CreatedOn.split(" ");
                      const date = dateTimeParts[0].replace(/-/g, "/");
                      const timeParts = dateTimeParts[1].split(":");
                      const time = `${timeParts[0]}:${timeParts[1]} ${dateTimeParts[2]}`;
                      return (
                        <DocumentCard
                          key={index}
                          img="in"
                          name={item.DisplayName}
                          date={date}
                          hour={time}
                          status={
                            item?.DocOutStatus.Label === "Executed" &&
                            item?.DocInStatus.Label === "Executed"
                              ? `${dataInfo[favoriteLenguaje][3]?.read}`
                              : `${dataInfo[favoriteLenguaje][3]?.received}`
                          }
                          imgContact={item.AvatarUrl ? item.AvatarUrl : avatar2}
                          color={
                            item?.DocOutStatus.Label === "Executed"
                              ? "#06b576"
                              : "#EBD300"
                          }
                          enterprise={item.EntityName}
                          onClick={() => {
                            if( item?.DocOutStatus.Label === "Executed" &&
                              item?.DocInStatus.Label === "Executed"){
                                setShow2(true);
                                sendParams(item.AccountId_Recipient, item.Id);
                              } else{
                                navigate(
                            `/view-document/${item.AccountId_Recipient}/${item.Id}/true`
                            );
                              }

                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : null}

              {stepInfo === 1 ? (
                <div className="documents-container">
                  <div
                    className="mb-4"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <label
                      htmlFor="exampleFormControlInput10"
                      className="form-label label-title"
                    >
                      {dataInfo[favoriteLenguaje][2]?.search}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      //   value={formulario.Email}
                      name="Search"
                      //   onChange={handleChange2}
                      style={{ padding: "10px", height: "52px" }}
                      id="exampleFormControlInput10"
                      placeholder={`🔎 ${dataInfo[favoriteLenguaje][2]?.search}`}
                      required
                      autoComplete="false"
                    />
                  </div>
                  <div className="container01">
                    {listDocumentReceived?.map((item, index) => {
                      const dateTimeParts = item.CreatedOn.split(" ");
                      const date = dateTimeParts[0].replace(/-/g, "/");
                      const timeParts = dateTimeParts[1].split(":");
                      const time = `${timeParts[0]}:${timeParts[1]} ${dateTimeParts[2]}`;
                      return (
                        <DocumentCard
                          key={index}
                          img="in"
                          name={item.DisplayName}
                          date={date}
                          hour={time}
                          status={
                            item?.DocOutStatus.Label === "Executed" &&
                            item?.DocInStatus.Label === "Executed"
                              ? `${dataInfo[favoriteLenguaje][3]?.executed}`
                              : `${dataInfo[favoriteLenguaje][3]?.received}`
                          }
                          imgContact={item.AvatarUrl ? item.AvatarUrl : avatar2}
                          color={
                            item?.DocOutStatus.Label === "Executed"
                              ? "#06b576"
                              : "#EBD300"
                          }
                          enterprise={item.EntityName}
                          onClick={() => {
                            if( item?.DocOutStatus.Label === "Executed" &&
                              item?.DocInStatus.Label === "Executed"){
                                setShow2(true);
                              } else{
                                navigate(
                            `/view-document/${item.AccountId_Recipient}/${item.Id}/true`
                            );
                              }

                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : null}

              {stepInfo === 2 ? (
                <div className="documents-container">
                  <div
                    className="mb-4"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <label
                      htmlFor="exampleFormControlInput10"
                      className="form-label label-title"
                    >
                      {dataInfo[favoriteLenguaje][2]?.search}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      //   value={formulario.Email}
                      name="Search"
                      //   onChange={handleChange2}
                      style={{ padding: "10px", height: "52px" }}
                      id="exampleFormControlInput10"
                      placeholder={`🔎 ${dataInfo[favoriteLenguaje][2]?.search}`}
                      required
                      autoComplete="false"
                    />
                  </div>
                  <div className="container01">
                    {listDocumentSend?.map((item, index) => {
                      const dateTimeParts = item.CreatedOn.split(" ");
                      const date = dateTimeParts[0].replace(/-/g, "/");
                      const timeParts = dateTimeParts[1].split(":");
                      const time = `${timeParts[0]}:${timeParts[1]} ${dateTimeParts[2]}`;
                      return (
                        <DocumentCard
                          key={index}
                          img="out"
                          name={item.InvitationName}
                          date={date}
                          hour={time}
                          status={
                            item?.DocOutStatus.Label === "Executed" &&
                            item?.DocInStatus.Label === "Executed"
                              ? `${dataInfo[favoriteLenguaje][3]?.executed}`
                              : `${dataInfo[favoriteLenguaje][3]?.sent}`
                          }
                          imgContact={item.AvatarUrl ? item.AvatarUrl : avatar2}
                          color={
                            item?.DocOutStatus.Label === "Executed"
                              ? "#06b576"
                              : "#EA4335"
                          }
                          enterprise={item.EntityName}
                          onClick={() => {
                            // navigate(
                            //   `/view-document/${item.AccountId_Recipient}/${item.Id}/false`
                            // );
                            setShow(true);
                            sendParams(item.AccountId_Recipient, item.Id);
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : null}

              {stepInfo === 3 ? (
                <div className="documents-container">
                  <div
                    className="mb-4"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <label
                      htmlFor="exampleFormControlInput10"
                      className="form-label label-title"
                    >
                      {dataInfo[favoriteLenguaje][2]?.search}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      //   value={formulario.Email}
                      name="Search"
                      //   onChange={handleChange2}
                      style={{ padding: "10px", height: "52px" }}
                      id="exampleFormControlInput10"
                      placeholder={`🔎 ${dataInfo[favoriteLenguaje][2]?.search}`}
                      required
                      autoComplete="false"
                    />
                  </div>
                  <div className="container01">
                    {listDocumentSend
                      ?.filter((item) => item.DocInStatus.Label === "Executed")
                      .map((item, index) => {
                        const dateTimeParts = item.CreatedOn.split(" ");
                        const date = dateTimeParts[0].replace(/-/g, "/");
                        const timeParts = dateTimeParts[1].split(":");
                        const time = `${timeParts[0]}:${timeParts[1]} ${dateTimeParts[2]}`;
                        return (
                          <DocumentCard
                            key={index}
                            img="out"
                            name={item.InvitationName}
                            date={date}
                            hour={time}
                            status={
                              item?.DocOutStatus.Label === "Executed" &&
                              item?.DocInStatus.Label === "Executed"
                                ? `${dataInfo[favoriteLenguaje][3]?.executed}`
                                : `${dataInfo[favoriteLenguaje][3]?.sent}`
                            }
                            imgContact={
                              item.AvatarUrl ? item.AvatarUrl : avatar2
                            }
                            color={
                              item?.DocOutStatus.Label === "Executed" &&
                              item?.DocInStatus.Label === "Executed"
                                ? "#06b576"
                                : "#EA4335"
                            }
                            enterprise={item.EntityName}
                            onClick={() => {
                              // navigate(
                              //   `/view-document/${item.AccountId_Recipient}/${item.Id}/false`
                              // );
                              setShow(true);
                              sendParams(item.AccountId_Recipient, item.Id);
                            }}
                          />
                        );
                      })}

                    {listDocumentReceived?.filter((item) => item.DocInStatus.Label === "Executed")
                    .map((item, index) => {
                      const dateTimeParts = item.CreatedOn.split(" ");
                      const date = dateTimeParts[0].replace(/-/g, "/");
                      const timeParts = dateTimeParts[1].split(":");
                      const time = `${timeParts[0]}:${timeParts[1]} ${dateTimeParts[2]}`;
                      return (
                        <DocumentCard
                          key={index}
                          img="in"
                          name={item.DisplayName}
                          date={date}
                          hour={time}
                          status={
                            item?.DocOutStatus.Label === "Executed" &&
                            item?.DocInStatus.Label === "Executed"
                              ? `${dataInfo[favoriteLenguaje][3]?.read}`
                              : `${dataInfo[favoriteLenguaje][3]?.received}`
                          }
                          imgContact={item.AvatarUrl ? item.AvatarUrl : avatar2}
                          color={
                            item?.DocOutStatus.Label === "Executed"
                              ? "#06b576"
                              : "#EBD300"
                          }
                          enterprise={item.EntityName}
                          onClick={() => {
                            if( item?.DocOutStatus.Label === "Executed" &&
                              item?.DocInStatus.Label === "Executed"){
                                setShow2(true);
                              } else{
                                navigate(
                            `/view-document/${item.AccountId_Recipient}/${item.Id}/true`
                            );
                              }

                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="doc-footer">© Copyright {year} NDABee.</div>
    </div>
    <InfoModal2
        notificationImage={notification}
        cancel={() => {
          setShow(false);
          sendAgain();
        }}
        submit={() => {
          setShow(false);
          console.log("prueba", accountId, documentId)
        }}
        notificationTitle={dataInfo[favoriteLenguaje][5]?.sendAgain}
        show={show}
        handleClose={handleClose}
        notificationMessage={
          <div>
            {dataInfo[favoriteLenguaje][5]?.sendAgainText}
          </div>
        }
      />
       <InfoModal2
        notificationImage={notification}
        cancel={() => {
          setShow2(false);
          getDocument();
          sendCopyDocument()
          
        }}
        submit={() => {
          setShow2(false);
          console.log("prueba", accountId, documentId)
        }}
        notificationTitle={dataInfo[favoriteLenguaje][5]?.ndaCopy}
        show={show2}
        handleClose={handleClose2}
        notificationMessage={
          <div>
            {dataInfo[favoriteLenguaje][5]?.ndaCopyText}
          </div>
        }
      />
       <ToastContainer theme="colored" position="top-right" autoClose={2000} />
    </>
  );
};

export default Docs;
