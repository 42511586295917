import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./LoginCard.css";
import { loginSuccess } from "../../redux/features/authSlice";
import { useNavigate } from "react-router-dom";
import axios from "./../../../node_modules/axios/dist/esm/axios";
import { BASE_URL, HEADERS, SERVICE_AUTH } from "../../Constants/Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { hideLoading, showLoading } from "../../redux/features/alertSlice";
import { CheckRegistration } from "../../Apis/Members/Models/CheckRegistration";
import { Authentication } from './../../Apis/Members/Models/Authentication';
import { GenerateOtp } from './../../Apis/Members/Models/GenerateOtp';
import { ValidateOtp } from './../../Apis/Members/Models/ValidateOtp';
import { dataInfo } from "../../pruebasJson/data";
import { SaveNotifications } from "../../Apis/Members/Models/SaveNotifications";
import { USER_ID } from "../../Constants/User";
import {
  setAcitveNav,
  setAvatarURL,
  setFavoriteLenguaje,
  setFirstName,
  setLastName,
} from "../../redux/features/userSlice";

const LoginCard = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checkData, setCheckData] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const baseUrl = BASE_URL + SERVICE_AUTH;
  const deviceId = localStorage.getItem("deviceId");
  const deviceEmail = localStorage.getItem("deviceEmail");
  const { favoriteLenguaje } = useSelector((state) => state.user);
  const currentUserId = USER_ID();

  const saveDateToLocalStorage = () => {
    const date = new Date();
    sessionStorage.setItem('storedDate', date.toString());
  };

  const login = async () => {
    dispatch(showLoading())
    const emailDevice = localStorage.getItem("deviceEmail");
    if (!emailDevice) {
      localStorage.setItem("deviceEmail", email);
    }
    try {
      const classRef = new Authentication();
      await classRef.List({
        Email: email,
        Password: password,
      })
      let result = classRef.ResponseBody;
      dispatch(hideLoading())
      if (result.Authentication && result.Authentication.IsAuthenticated === true) {
        toast.success(result.Authentication.HttpErrorMessage);
        let jsondata = { Auth: { ...result } };
        saveDateToLocalStorage();
        localStorage.setItem("User", JSON.stringify(jsondata));
        window.location.href = "/";
      } else {
        // console.log("No se ha logueado", result);
        toast.warning(result.message);
      }
    } catch (error) {
      dispatch(hideLoading())
      toast.error(error);
      console.error(error);
    }
  };

  const OtpLogin =  async () => {
    dispatch(showLoading())
    const storageEmail = localStorage.getItem("deviceEmail");
    const storageDeviceId = localStorage.getItem("deviceId");
    if (!storageEmail) {
      toast.warning(dataInfo[favoriteLenguaje][5]?.warningEmail);
    } else if (!storageDeviceId) {
      toast.warning(dataInfo[favoriteLenguaje][5]?.warningDevice);
    } else {
      try {
        const classRef = new GenerateOtp();
        await classRef.List({
          Email: storageEmail,
          DeviceId: storageDeviceId,
        })
        let result = classRef.ResponseBody;
        if(result.Otp){
          validateOtp(result.Otp)
        }

      } catch (error) {
        console.log("Error", error);
      }
    }
  };

  const validateOtp = async (otp) => {
    const storageEmail = localStorage.getItem("deviceEmail");

    try {
      const classRef = new ValidateOtp();
      await classRef.List({
        Email: storageEmail,
        Otp: otp,
      })
      let result = classRef.ResponseBody;

      dispatch(hideLoading())
      if (result.Authentication && result.Authentication.IsAuthenticated === true) {
        toast.success(result.Authentication.HttpErrorMessage);
        let jsondata = { Auth: { ...result } };
        localStorage.setItem("User", JSON.stringify(jsondata));
        window.location.href = "/";
      } else {
        toast.warning(result.message);
      }
    } catch (error) {
      dispatch(hideLoading())
      toast.error(error);
      console.error(error);
    }
  };

  const checkRegitration = async () => {
    try {
      if(deviceEmail){
        const classRef = new CheckRegistration();
        await classRef.List({"Email":deviceEmail})
        let result = classRef.ResponseBody;
        // console.log("Result check", result)
        setEmail(deviceEmail)
        setCheckData(result)
      } else{
        console.log("No emial in localStorage")
      } 
    } catch (error) {
      console.log("Error", error)
    }
  }

  useEffect(()=>{
    checkRegitration();
  },[])

  return (
    <div className="login-card-container">
      <div className="mt-4">
        <img src={props.image} alt={`iamgen de ${props.image}`} />
      </div>
      <div className="logincard-title-container">
        <div className="logincard-title">Welcome</div>
        <div className="logincard-subttitle">
        {dataInfo[favoriteLenguaje][3]?.welcomeSubtitle}  <br />  <span className="">{dataInfo[favoriteLenguaje][3]?.welcomeSubtitle2}  {" "}</span> 
          {`${props.loginMode}`}
        </div>
      </div>
      <div className="logincard-line mt-4 mb-4"></div>
      <div className="logincard-from">
        {props.loginStage === "credentials" ? (
          <>
            <div
              className="mb-4"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label
                htmlFor="logincard-email2"
                className="form-label label-title"
              >
                {dataInfo[favoriteLenguaje][2]?.email}
              </label>
              <input
                type="email"
                className="form-control"
                style={{ padding: "10px", height: "52px" }}
                id="logincard-email2"
                placeholder= {dataInfo[favoriteLenguaje][2]?.email}
                autoComplete="off"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>

            <div
              className="mb-4"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label
                htmlFor="logincard-password"
                className="form-label label-title"
              >
                {dataInfo[favoriteLenguaje][2]?.password}
              </label>
              <input
                type="password"
                className="form-control"
                style={{ padding: "10px", height: "52px" }}
                id="logincard-password"
                placeholder={dataInfo[favoriteLenguaje][2]?.password}
                autoComplete="off"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </div>

            <div className="login-record-forgot">
              {/* <div
                className="form-check"
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <input
                  className="form-check-input border-primary"
                  type="checkbox"
                  id="customCheckbox"
                  style={{
                    width: "1.3em",
                    height: "1.3em",
                    border: "3px solid",
                    cursor: "pointer",
                  }}
                />
                <label
                  className="form-check-label logincard-label"
                  htmlFor="customCheckbox"
                  style={{ paddingTop: "4px" }}
                >
                  Remember Me
                </label>
              </div> */}
              {checkData?.PasswordRequired === false && deviceId && deviceEmail ? (
                <div className="send-me-otp" onClick={OtpLogin}>
                 {dataInfo[favoriteLenguaje][2]?.sendMeOtp}
                </div>
              ) : checkData?.PasswordRequired === true ? (
                <div className="complete-registration">
                  * {dataInfo[favoriteLenguaje][2]?.completeRegistration}
                </div>
              ) :
              <div>
                </div>}

              <div className="forgot-password-title" onClick={props.onClick}>
              {dataInfo[favoriteLenguaje][2]?.forgotPassword}
              </div>
            </div>

            <div
              className="col-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="green-line"></div>
            </div>

            <div className="col-12">
              <button
                className="btn btn-primary w-100 mb-3"
                style={{ height: "50px" }}
                onClick={login}
              >
                {dataInfo[favoriteLenguaje][3]?.login}
              </button>
            </div>
            <div
              className="col-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="green-line"></div>
            </div>
          </>
        ) : null}

        {props.loginStage === "facebook" ? (
          <>
            <div
              className="mb-4"
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "65px",
                paddingBottom: "65px",
              }}
            >
              <label
                htmlFor="logincard-facebook"
                className="form-label label-title2"
              >
                Facebook Account
              </label>
              <div
                className="form-control logincard-email"
                style={{
                  padding: "10px",
                  height: "52px",
                  border: "none",
                  backgroundColor: "transparent",
                  width: "100%",
                  textAlign: "center",
                }}
                autoComplete="off"
              >
                example@example.com
              </div>
            </div>

            <div
              className="col-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="green-line"></div>
            </div>

            <div className="col-12">
              <button
                className="btn btn-primary w-100 mb-3"
                style={{ height: "50px" }}
                onClick={login}
              >
               {dataInfo[favoriteLenguaje][3]?.login}
              </button>
            </div>

            <div
              className="col-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="green-line"></div>
            </div>
          </>
        ) : null}

        {props.loginStage === "google" ? (
          <>
            <div
              className="mb-4"
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "65px",
                paddingBottom: "65px",
              }}
            >
              <label
                htmlFor="logincard-Google"
                className="form-label label-title2"
              >
                Google Account
              </label>
              <div
                className="form-control logincard-email"
                style={{
                  padding: "10px",
                  height: "52px",
                  border: "none",
                  backgroundColor: "transparent",
                  width: "100%",
                  textAlign: "center",
                }}
                autoComplete="off"
              >
                example@example.com
              </div>
            </div>

            <div
              className="col-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="green-line"></div>
            </div>

            <div className="col-12">
              <button
                className="btn btn-primary w-100 mb-3"
                style={{ height: "50px" }}
                onClick={login}
              >
                {dataInfo[favoriteLenguaje][3]?.login}
              </button>
            </div>

            <div
              className="col-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="green-line"></div>
            </div>
          </>
        ) : null}

        {props.loginStage === "linkedin" ? (
          <>
            <div
              className="mb-4"
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "65px",
                paddingBottom: "65px",
              }}
            >
              <label
                htmlFor="logincard-Linkedin"
                className="form-label label-title2"
              >
                Linkedin Account
              </label>
              <div
                className="form-control logincard-email"
                style={{
                  padding: "10px",
                  height: "52px",
                  border: "none",
                  backgroundColor: "transparent",
                  width: "100%",
                  textAlign: "center",
                }}
                autoComplete="off"
              >
                example@example.com
              </div>
            </div>

            <div
              className="col-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="green-line"></div>
            </div>

            <div className="col-12">
              <button
                className="btn btn-primary w-100 mb-3"
                style={{ height: "50px" }}
                onClick={login}
              >
                {dataInfo[favoriteLenguaje][3]?.login}
              </button>
            </div>

            <div
              className="col-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="green-line"></div>
            </div>
          </>
        ) : null}

        {props.loginStage === "apple" ? (
          <>
            <div
              className="mb-4"
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "65px",
                paddingBottom: "65px",
              }}
            >
              <label
                htmlFor="logincard-Apple"
                className="form-label label-title2"
              >
                Apple Account
              </label>
              <div
                className="form-control logincard-email"
                style={{
                  padding: "10px",
                  height: "52px",
                  border: "none",
                  backgroundColor: "transparent",
                  width: "100%",
                  textAlign: "center",
                }}
                autoComplete="off"
              >
                example@example.com
              </div>
            </div>

            <div
              className="col-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="green-line"></div>
            </div>

            <div className="col-12">
              <button
                className="btn btn-primary w-100 mb-3"
                style={{ height: "50px" }}
                onClick={login}
              >
              {dataInfo[favoriteLenguaje][3]?.login}
              </button>
            </div>

            <div
              className="col-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="green-line"></div>
            </div>
          </>
        ) : null}
      </div>
      <ToastContainer theme="colored" position="top-right" autoClose={2000} />
    </div>
  );
};

export default LoginCard;
