import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";
import "./SignupCard.css";
import avatar from "../../images/avatar.png";
import plusAvatar from "../../images/addAvatar.png";
import { useNavigate } from "react-router-dom";
import defaultCheck from "../../images/default-check.png";
import rightCheck from "../../images/right-check.png";
import wrongCheck from "../../images/wrong-check.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BASE_URL,
  SERVICES,
  SERVICES_NAME,
  VERSION,
} from "../../Constants/Auth";
import { SaveAvatar } from "../../Apis/Members/Models/SaveAvatar";
import { Register } from "../../Apis/Members/Models/Register";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useSelector, useDispatch } from "react-redux";
import { dataInfo } from "../../pruebasJson/data";
import { hideLoading, showLoading } from "../../redux/features/alertSlice";
import { Authentication } from "../../Apis/Members/Models/Authentication";

const SignupCard = (props) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [valid, setValid] = useState(true);
  const [stage, setStage] = useState("credentials-1");
  const [signupTitle, setSignuptitle] = useState("Ready to join us?");
  const [formulario, setFormulario] = useState({
    firstname: "",
    lastname: "",
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
  });
  // const [usernameError, setUsernameError] = useState("");
  const [lengthError, setLengthError] = useState("");
  const [characterError, setCharacterError] = useState("");
  const [numericError, setNumericError] = useState("");
  const [symbolError, setSymbolrror] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [updateImg, setUpdateImg] = useState(null);
  const [editImage, setEditImage] = useState(null);
  const [dialCode, setDialCode] = useState("");
  const [dialCode2, setDialCode2] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [userIdInfo, setUserIdInfo] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [src, setSrc] = useState(null);
  const [cropper, setCropper] = useState();
  const [cropData, setCropData] = useState("#");
  const baseUrl = BASE_URL + SERVICES + "/" + VERSION + "/" + SERVICES_NAME;
  const dispatch = useDispatch();
  const deviceId = localStorage.getItem("deviceId");
  const deviceEmail = localStorage.getItem("deviceEmail");
  

  const handleShow = () => setModalShow(true);
  const handleClose = () => setModalShow(false);
  const { favoriteLenguaje } = useSelector((state) => state.user);

  const saveDateToLocalStorage = () => {
    const date = new Date();
    sessionStorage.setItem('storedDate', date.toString());
  };


  const handleFormChange2 = (e) => {
    const { name, value } = e.target;
    setFormulario({
      ...formulario,
      [name]: value,
    });

    // Realizar verificaciones en tiempo real
    const password = value;
    // const username = formulario.username;

    // let hasUsernameError =
    //   password.toLowerCase().includes(username.toLowerCase()) || !password;
    let hasLengthError = password.length < 6;
    let hasUppercaseError = !/[A-Z]/.test(password);
    let hasNumericError = !/\d/.test(password);
    let hasSymbolError = !/[!@#$%^&*()]/.test(password);

    // setUsernameError(hasUsernameError);
    setLengthError(hasLengthError);
    setCharacterError(hasUppercaseError);
    setNumericError(hasNumericError);
    setSymbolrror(hasSymbolError);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormulario({
      ...formulario,
      [name]: value,
    });
  };

  const handleChange = (value) => {
    setPhoneNumber(value);
    setValid(validatePhoneNumber(value));
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;

    return phoneNumberPattern.test(phoneNumber);
  };

  const handleNextStage = () => {
    const password = formulario?.password;
    // const username = formulario?.username;

    if (!password) {
      toast.warning(dataInfo[favoriteLenguaje][5]?.warningRequireUsername);
      return;
    }

    if (
      !lengthError &&
      !characterError &&
      !numericError &&
      !symbolError
    ) {
      const confirmPassword = formulario.confirmPassword;
      if (!confirmPassword) {
        return toast.warning(dataInfo[favoriteLenguaje][5]?.warningConfirmPassword);
      }

      if (confirmPassword === password) {
        handleRegister();
      } else {
        toast.warning(dataInfo[favoriteLenguaje][5]?.warningConfirmPassword2);
      }
    }
  };

  /*Input avatar*/
  const handleFileChange = (event) => {
    // const file = event.target.files[0];
    // setSelectedFile(file);

    // if (file) {
    //   const reader = new FileReader();
    //   reader.onload = (e) => {
    //     setImagePreview(e.target.result);
    //     setEditImage(null);
    //     setUpdateImg(true);
    //   };
    //   reader.readAsDataURL(file);
    // } else {
    //   setImagePreview(null);
    //   setUpdateImg(null);
    // }
    // event.target.value = null;
    const file = event.target.files[0];
    setSelectedFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        // setImagePreview(e.target.result);
        setEditImage(null);
        setUpdateImg(true);
        setSrc(e.target.result); // Set the base64 string as the source for Avatar
        setModalShow(true); // Show the modal when the file is selected
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
      setUpdateImg(null);
    }
    event.target.value = null;
  };

  const handlePhoneChange = (value, country, e, formattedValue) => {
    setPhoneNumber(value);
    setDialCode("+" + country.dialCode);
    setDialCode2(country.dialCode);
    setValid(validatePhoneNumber(formattedValue));
    setSelectedCountry(country.name);
  };

  const handleRegister = async () => {
    const arrayNumber = phoneNumber.split("");
    const dialArray = dialCode2.split("");
    const cleanArray = [];
    let cleanString = "";
    console.log("Array number", arrayNumber);
    console.log("Dial array", dialArray);

    for (let i = 0; i < arrayNumber.length; i++) {
      if (dialArray[i] !== "") {
        if (dialArray[i] !== arrayNumber[i]) {
          cleanArray.push(arrayNumber[i]);
          cleanString = cleanString + arrayNumber[i];
        }
      } else {
        cleanArray.push(arrayNumber[i]);
        cleanString = cleanString + arrayNumber[i];
      }
    }
    console.log("Clean Array =>>", cleanArray);
    console.log("String number", cleanString);

    try {
      const classRef = new Register();
      await classRef.List({
        Email: formulario.email,
        Password: formulario.password,
        SocialFacebookId: "",
        SocialFacebookToken: "",
        SocialLinkedInId: "",
        SocialLinkedInToken: "",
        SocialGmailId: "",
        FirstName: formulario.firstname,
        LastName: formulario.lastname,
        // UserName: formulario.username,
        Country: selectedCountry,
        PhoneCountryCode: dialCode,
        PhoneNo: cleanString,
      });
      let result = classRef.ResponseBody;
      if (result.MemberInfo) {
        const id = result.MemberInfo.Id;
        setUserIdInfo(id);
        setStage("credentials-3");
        setSignuptitle("Avatar");
        toast.success(dataInfo[favoriteLenguaje][5]?.successRegister);
      }
    } catch (error) {
      console.log("Error", error);
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          props.onClick();
        }, 2000);
      } else {
        toast.error(error.response.data.message);
        setTimeout(() => {
          props.onClick();
        }, 2000);
      }
    }
  };

  const login = async () => {
    dispatch(showLoading())
    const emailDevice = localStorage.getItem("deviceEmail");
    if (!emailDevice) {
      localStorage.setItem("deviceEmail", formulario.email);
    }
    try {
      const classRef = new Authentication();
      await classRef.List({
        Email: formulario.email,
        Password: formulario.password,
      })
      let result = classRef.ResponseBody;
      dispatch(hideLoading())
      if (result.Authentication && result.Authentication.IsAuthenticated === true) {
        toast.success(result.Authentication.HttpErrorMessage);
        let jsondata = { Auth: { ...result } };
        saveDateToLocalStorage();
        localStorage.setItem("User", JSON.stringify(jsondata));
        window.location.href = "/";
      } else {
        // console.log("No se ha logueado", result);
        toast.warning(result.message);
      }
    } catch (error) {
      dispatch(hideLoading())
      toast.error(error);
      console.error(error);
    }
  };

  const handleUpload = async (id) => {
    var formdata = new FormData();
    formdata.append("MembersId", id.toString());
    formdata.append("File", selectedFile, "[PROXY]");

    try {
      const classRef = new SaveAvatar();
      await classRef.List(formdata);
      let result = classRef.ResponseBody;
      if (result) {
        toast.success(dataInfo[favoriteLenguaje][5]?.successAvatar);
        setTimeout(() => {
          login();
        }, 1500);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const b64toBlob = async (dataURI) => {
    const base64file = await fetch(dataURI)
    const blobFile = await base64file.blob()
    return blobFile
  }


  const saveCrop = async () => {
    if (typeof cropper !== 'undefined') {
      let cropperCanvas = cropper.getCroppedCanvas()
      setCropData(cropperCanvas.toDataURL())
      const fileCropped = await b64toBlob(cropperCanvas.toDataURL())
      setImagePreview(cropperCanvas.toDataURL());
      setSelectedFile(fileCropped);
      console.log("Data", cropperCanvas.toDataURL())
      // setImageState({ file: fileCropped });

    }
    // setSelectedFile(preview);
    // setImagePreview(preview);
    setModalShow(false);
  };

  useEffect(() => {
    setSignuptitle(dataInfo[favoriteLenguaje][3]?.registration)
  }, []);

  return (
    <div className="signup-card-container">
      <div className="signup-title">{signupTitle}</div>
      <div className="signup-subtitle">
        {dataInfo[favoriteLenguaje][3]?.completeRegistration}
      </div>
      <div className="signupcard-from">
        {props.singupStage === "credentials" && stage === "credentials-1" ? (
          <>
            <div
              className="mb-4"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label
                htmlFor="signup-fistname"
                className="form-label label-title"
              >
                {dataInfo[favoriteLenguaje][2]?.firstname}
              </label>
              <input
                type="text"
                className="form-control"
                style={{ padding: "10px", height: "52px" }}
                id="signup-fistname"
                placeholder={dataInfo[favoriteLenguaje][2]?.firstname}
                name="firstname"
                autoComplete="off"
                value={formulario.firstname ? formulario.firstname : ""}
                onChange={handleFormChange}
              />
            </div>

            <div
              className="mb-4"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label
                htmlFor="signup-lastname"
                className="form-label label-title"
              >
                {dataInfo[favoriteLenguaje][2]?.lastname}
              </label>
              <input
                type="text"
                className="form-control"
                style={{ padding: "10px", height: "52px" }}
                id="signup-lastname"
                placeholder={dataInfo[favoriteLenguaje][2]?.lastname}
                autoComplete="off"
                name="lastname"
                value={formulario.lastname ? formulario.lastname : ""}
                onChange={handleFormChange}
              />
            </div>

            <div
              className="mb-4"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="signup-phone" className="form-label label-title">
                {dataInfo[favoriteLenguaje][2]?.phoneNumber}
              </label>
              <PhoneInput
                country={"us"}
                value={phoneNumber}
                onChange={handlePhoneChange}
                inputProps={{
                  style: {
                    height: "52px",
                    width: "100%",
                    border: "1px solid lightgray",
                  },
                  required: true,
                }}
              />
            </div>

            <div
              className="mb-4"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="signup-email" className="form-label label-title">
                {dataInfo[favoriteLenguaje][2]?.email}
              </label>
              <input
                type="email"
                className="form-control"
                style={{ padding: "10px", height: "52px" }}
                id="signup-email"
                placeholder={dataInfo[favoriteLenguaje][2]?.email}
                autoComplete="off"
                name="email"
                value={formulario.email ? formulario.email : ""}
                onChange={handleFormChange}
              />
            </div>

            <div
              className="col-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="green-line"></div>
            </div>

            <div className="col-12">
              <button
                className="btn btn-primary w-100 mb-3"
                style={{ height: "50px" }}
                onClick={() => {
                  if (formulario?.firstname === "") {
                    toast.warning(dataInfo[favoriteLenguaje][5]?.warningFistname);
                  } else if (formulario?.lastname === "") {
                    toast.warning(dataInfo[favoriteLenguaje][5]?.warningLastname);
                  } else if (phoneNumber === "") {
                    toast.warning(dataInfo[favoriteLenguaje][5]?.warningPhone);
                  } else if (formulario?.email === "") {
                    toast.warning(dataInfo[favoriteLenguaje][5]?.warningEmail2);
                  } else {
                    setStage("credentials-2");
                    setSignuptitle(dataInfo[favoriteLenguaje][3]?.signupTitle);
                  }
                }}
              >
                {dataInfo[favoriteLenguaje][3]?.next}
              </button>
            </div>
            <div
              className="col-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="green-line"></div>
            </div>
          </>
        ) : null}

        {props.singupStage === "credentials" && stage === "credentials-2" ? (
          <>
            {/* <div
              className="mb-4"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label
                htmlFor="signup-username"
                className="form-label label-title"
              >
                {dataInfo[favoriteLenguaje][2]?.username}
              </label>
              <input
                type="text"
                className="form-control"
                style={{ padding: "10px", height: "52px" }}
                id="signup-username"
                placeholder={dataInfo[favoriteLenguaje][2]?.username}
                autoComplete="off"
                key="uniqueKey"
                name="username"
                value={formulario.username ? formulario.username : ""}
                onChange={handleFormChange}
              />
            </div> */}

            <div
              className="mt-4 mb-2"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label
                htmlFor="signup-password"
                className="form-label label-title"
              >
                {dataInfo[favoriteLenguaje][2]?.password}
              </label>
              <input
                type="password"
                className="form-control"
                style={{ padding: "10px", height: "52px" }}
                id="signup-password"
                placeholder={dataInfo[favoriteLenguaje][2]?.password}
                autoComplete="off"
                key="uniqueKey"
                name="password"
                value={formulario.password ? formulario.password : ""}
                onChange={handleFormChange2}
              />
            </div>
            <div className="mb-2">
              {/* <div className="verify-subcontainer">
                <div>
                  <img
                    src={
                      usernameError === ""
                        ? defaultCheck
                        : !usernameError
                        ? rightCheck
                        : wrongCheck
                    }
                    alt="check"
                    width="18px"
                    height="18px"
                  />
                </div>
                <div
                  className="verify-password"
                  style={{
                    color: `${
                      usernameError === ""
                        ? "#676767"
                        : !usernameError
                        ? "#06B576"
                        : "red"
                    }`,
                  }}
                >
                 {dataInfo[favoriteLenguaje][3]?.passwordError1}
                </div>
              </div> */}
              <div className="verify-subcontainer mb-1">
                <div>
                  <img
                    src={
                      lengthError === ""
                        ? defaultCheck
                        : !lengthError
                          ? rightCheck
                          : wrongCheck
                    }
                    alt="check"
                    width="18px"
                    height="18px"
                  />
                </div>
                <div
                  className="verify-password2"
                  style={{
                    color: `${lengthError === ""
                        ? "#676767"
                        : !lengthError
                          ? "#06B576"
                          : "red"
                      }`,
                  }}
                >
                  {dataInfo[favoriteLenguaje][3]?.passwordError2}
                </div>
              </div>
              <div className="verify-subcontainer  mb-1">
                <div>
                  <img
                    src={
                      characterError === ""
                        ? defaultCheck
                        : !characterError
                          ? rightCheck
                          : wrongCheck
                    }
                    alt="check"
                    width="18px"
                    height="18px"
                  />
                </div>
                <div
                  className="verify-password"
                  style={{
                    color: `${characterError === ""
                        ? "#676767"
                        : !characterError
                          ? "#06B576"
                          : "red"
                      }`,
                  }}
                >
                  {dataInfo[favoriteLenguaje][3]?.passwordError3}
                </div>
              </div>
              <div className="verify-subcontainer  mb-1">
                <div>
                  <img
                    src={
                      numericError === ""
                        ? defaultCheck
                        : !numericError
                          ? rightCheck
                          : wrongCheck
                    }
                    alt="check"
                    width="18px"
                    height="18px"
                  />
                </div>
                <div
                  className="verify-password"
                  style={{
                    color: `${numericError === ""
                        ? "#676767"
                        : !numericError
                          ? "#06B576"
                          : "red"
                      }`,
                  }}
                >
                  {dataInfo[favoriteLenguaje][3]?.passwordError4}
                </div>
              </div>
              <div className="verify-subcontainer  mb-4">
                <div>
                  <img
                    src={
                      symbolError === ""
                        ? defaultCheck
                        : !symbolError
                          ? rightCheck
                          : wrongCheck
                    }
                    alt="check"
                    width="18px"
                    height="18px"
                  />
                </div>
                <div
                  className="verify-password"
                  style={{
                    color: `${symbolError === ""
                        ? "#676767"
                        : !symbolError
                          ? "#06B576"
                          : "red"
                      }`,
                  }}
                >
                  {dataInfo[favoriteLenguaje][3]?.passwordError5}
                </div>
              </div>
            </div>
            <div
              className="mb-4"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label
                htmlFor="signup-confirm"
                className="form-label label-title"
              >
                {dataInfo[favoriteLenguaje][2]?.confirmPassword}
              </label>
              <input
                type="password"
                className="form-control"
                style={{ padding: "10px", height: "52px" }}
                id="signup-confirm"
                placeholder={dataInfo[favoriteLenguaje][2]?.confirmPassword}
                autoComplete="off"
                key="uniqueKey"
                name="confirmPassword"
                value={
                  formulario.confirmPassword ? formulario.confirmPassword : ""
                }
                onChange={handleFormChange}
              />
            </div>

            <div
              className="col-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="green-line"></div>
            </div>

            <div className="col-12">
              <button
                className="btn btn-primary w-100 mb-3"
                style={{ height: "50px" }}
                onClick={() => {
                  handleNextStage();
                  // console.log("==>>", formulario);
                  // console.log("==>>", phoneNumber);
                }}
              >
                {dataInfo[favoriteLenguaje][2]?.continue}
              </button>
            </div>
            <div
              className="col-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="green-line"></div>
            </div>
          </>
        ) : null}

        {props.singupStage === "credentials" && stage === "credentials-3" ? (
          <>
            <div className="signup-avatar-img">
              <img
                src={imagePreview ? imagePreview : avatar}
                alt="avatar"
                width="390px"
                height="390px"
                className={imagePreview ? "input-file" : null}
              />
              <input
                type="file"
                id="fileInput"
                accept=".jpg, .jpeg, .png"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <label htmlFor="fileInput" className="custom-file-button">
                <img
                  src={plusAvatar}
                  alt="avatar"
                  width="55.29px"
                  height="55.29px"
                  className="plus-avatar"
                />
              </label>
            </div>
            <div
              className="col-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="green-line" style={{ height: "3.2px" }}></div>
            </div>
            <div className="col-12">
              <button
                className="btn btn-primary w-100 mb-3"
                style={{ height: "50px" }}
                onClick={() => {
                  if (imagePreview) {
                    handleUpload(userIdInfo);
                  } else {
                    login();
                  }
                }}
              >
                {dataInfo[favoriteLenguaje][2]?.continue}
              </button>
            </div>
            <div
              className="col-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="green-line"></div>
            </div>
          </>
        ) : null}

        <div
          className={`modal ${modalShow ? "show" : ""}`}
          style={{ display: modalShow ? "block" : "none", zIndex: 1050 }}
          tabIndex="-1"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{dataInfo[favoriteLenguaje][2]?.editYourAvatar}</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={handleClose}
                ></button>
              </div>
              <div className="modal-body d-flex justify-content-center align-items-center">
                {/* <Avatar
               width={500}
               height={600}
               onCrop={onCrop}
               onClose={onClose}
               onBeforeFileLoad={onBeforeFileLoad}
               src={src ? src : avatar} // Use base64 string here
               borderRadius={0}
               cropRadius={0}
              /> */}
                <Cropper
                  aspectRatio={1 / 1}
                  src={src ? src : avatar}
                  zoomable={false}
                  minCropBoxHeight={200}
                  minCropBoxWidth={200}
                  background={false}
                  responsive={true}
                  cropBoxResizable={true}
                  checkOrientation={false}
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  guides={true}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleClose}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={saveCrop}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>

        {props.loginStage === "facebook" ? (
          <>
            <div
              className="mb-4"
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "65px",
                paddingBottom: "65px",
              }}
            >
              <label
                htmlFor="signup-facebbok"
                className="form-label label-title2"
              >
                Facebook Account
              </label>
              <div
                className="form-control logincard-email"
                style={{
                  padding: "10px",
                  height: "52px",
                  border: "none",
                  backgroundColor: "transparent",
                  width: "100%",
                  textAlign: "center",
                }}
                autoComplete="off"
              >
                example@example.com
              </div>
            </div>

            <div
              className="col-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="green-line"></div>
            </div>

            <div className="col-12">
              <button
                className="btn btn-primary w-100 mb-3"
                style={{ height: "50px" }}
                onClick={() => { }}
              >
                Login
              </button>
            </div>

            <div
              className="col-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="green-line"></div>
            </div>
          </>
        ) : null}

        {props.loginStage === "google" ? (
          <>
            <div
              className="mb-4"
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "65px",
                paddingBottom: "65px",
              }}
            >
              <label
                htmlFor="signup-Google"
                className="form-label label-title2"
              >
                Google Account
              </label>
              <div
                className="form-control logincard-email"
                style={{
                  padding: "10px",
                  height: "52px",
                  border: "none",
                  backgroundColor: "transparent",
                  width: "100%",
                  textAlign: "center",
                }}
                autoComplete="off"
              >
                example@example.com
              </div>
            </div>

            <div
              className="col-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="green-line"></div>
            </div>

            <div className="col-12">
              <button
                className="btn btn-primary w-100 mb-3"
                style={{ height: "50px" }}
                onClick={() => { }}
              >
                Login
              </button>
            </div>

            <div
              className="col-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="green-line"></div>
            </div>
          </>
        ) : null}

        {props.loginStage === "linkedin" ? (
          <>
            <div
              className="mb-4"
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "65px",
                paddingBottom: "65px",
              }}
            >
              <label
                htmlFor="signup-Linkedin"
                className="form-label label-title2"
              >
                Linkedin Account
              </label>
              <div
                className="form-control logincard-email"
                style={{
                  padding: "10px",
                  height: "52px",
                  border: "none",
                  backgroundColor: "transparent",
                  width: "100%",
                  textAlign: "center",
                }}
                autoComplete="off"
              >
                example@example.com
              </div>
            </div>

            <div
              className="col-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="green-line"></div>
            </div>

            <div className="col-12">
              <button
                className="btn btn-primary w-100 mb-3"
                style={{ height: "50px" }}
                onClick={() => { }}
              >
                Login
              </button>
            </div>

            <div
              className="col-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="green-line"></div>
            </div>
          </>
        ) : null}

        {props.loginStage === "apple" ? (
          <>
            <div
              className="mb-4"
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "65px",
                paddingBottom: "65px",
              }}
            >
              <label htmlFor="signup-Apple" className="form-label label-title2">
                Apple Account
              </label>
              <div
                className="form-control logincard-email"
                style={{
                  padding: "10px",
                  height: "52px",
                  border: "none",
                  backgroundColor: "transparent",
                  width: "100%",
                  textAlign: "center",
                }}
                autoComplete="off"
              >
                example@example.com
              </div>
            </div>

            <div
              className="col-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="green-line"></div>
            </div>

            <div className="col-12">
              <button
                className="btn btn-primary w-100 mb-3"
                style={{ height: "50px" }}
                onClick={() => { }}
              >
                Login
              </button>
            </div>

            <div
              className="col-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="green-line"></div>
            </div>
          </>
        ) : null}
      </div>
      <ToastContainer theme="colored" position="top-right" autoClose={2000} />
      {modalShow && (
        <div
          className="modal-backdrop fade show"
          style={{ zIndex: 1040 }}
        ></div>
      )}
    </div>
  );
};

export default SignupCard;
